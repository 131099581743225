import { parsePhoneNumber } from "libphonenumber-js";

const formatInternational = <T extends string | null | undefined>(params: {
  phoneNumber: T;
}) => {
  const { phoneNumber } = params;

  if (!phoneNumber) return phoneNumber;

  const isPrefixIncluded = phoneNumber.startsWith("+");

  try {
    const parsedPhoneNumber = parsePhoneNumber(
      `${isPrefixIncluded ? "" : "+"}${phoneNumber}`,
    );
    const internationalPhoneNumber = parsedPhoneNumber.formatInternational();

    const [countryCode, carrierCode, ...number] =
      internationalPhoneNumber.split(" ");

    if (number.length > 0) {
      return `${countryCode} (${carrierCode}) ${number.join(" ")}`;
    }

    if (carrierCode) {
      return `${countryCode} ${carrierCode}`;
    }

    return phoneNumber;
  } catch {
    return phoneNumber;
  }
};

export { formatInternational };
